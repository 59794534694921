<template>
  <div class="">
       <!-- <div class="w ww xx_list_c">
      <div class="xx_list_1">
        <ul>
          <li  v-for="(item,index) in studyListData" :key="index"  @click="docDetail(item.owid)"><a>
            <h3>{{item.categoryName}}</h3>
              <div class="remen_img"><img :src="pictrueUrl+item.studyShortImg" /></div>
              <h3>{{item.studyTitle}}</h3>
              <p>{{item.studyShortDesc}}</p>
            </a></li>
          <div class="clear"></div>
        </ul>
      </div>
    </div> -->
    <div class="w news-list" >
      <h3 v-if="studyListData">资源列表：</h3>
    <div  v-for="(item,index) in studyListData" :key="index" @click="docDetail(item.owid)">
      <span class="studyTitle">{{item.studyTitle}}</span>
    </div>
  </div>
    </div>
</template>
<script>
import { studyList} from "./model";
import { IMGURL } from '@/utils/constant'
export default {
  name: 'StudyCenter',
  data () {
    return {
      // 学习列表
      studyListData: '',
      owid:'',
      // studyPublishTime:''
    }
  },
 
  created () {
    this.owid = this.$route.query.owid;
    this.pictrueUrl = IMGURL;
    // 2.25
    studyList({categoryRefOwid:this.owid}).then(res => {
      this.studyListData = res.records;
     this.studyPublishTime = this.studyListData[0].studyPublishTime.slice(0,10)
    })
  },
  methods:{
     //进入详情
   docDetail(owid){
     let routeData = this.$router.resolve({
       path:'/detail',
       query:{docId:owid,type:'study'}
     })
      //技术中心详情
      window.open(routeData.href,"_blank")
    },
  }
}

</script>
<style scoped>
.remen_img {
  height: 1rem;
  margin-top: 0.1rem;
}
.studyTitle {
  text-align: left;
  padding-left: 1em;
  border: none;
  font-size: 0.07rem;
}
.news-list h3{
  margin-bottom: 0.1rem;
  font-size: 0.1rem;
}
</style>

